'use strict';

// Прелоадер
(function () {
  let preload = document.querySelector('.preload');

  if (preload) {
    setTimeout(function () {
      preload.classList.remove('preload--ready');
    }, 200);

    window.addEventListener('load', function () {
      preload.classList.add('child-hide');

      setTimeout(function () {
        preload.classList.add('active');
      }, 300);


      setTimeout(function () {
        // preload.remove();
      }, 1000);
    });
  }

})();

$(document).ready(function () {
  let bar = $(".dws-progress-bar"), // селектор прелоадера
    imagesCount = $('img').length, // количество изображений
    percent = 100 / imagesCount, // количество % на одну картинку
    progress = 0, // точка отсчета
    imgSum = 5, // количество картинок
    loadedImg = 0; // счетчик загрузки картинок

  if (imagesCount >= imgSum && imagesCount > 0 && bar.length > 0) {

    let size = (window.matchMedia("(min-width: 480px)").matches) ? "400px" : "300px";

    bar.circularProgress({
      color: "#1b64ad",
      line_width: 5,
      height: size,
      width: size,
      percent: 0,
      // counter_clockwise: true,
      starting_position: 0
    }).circularProgress('animate', percent, 1000);

    for (let i = 0; i < imagesCount; i++) { // создаем клоны изображений
      let img_copy = new Image();
      img_copy.src = document.images[i].src;
      img_copy.onload = img_load;
      img_copy.onerror = img_load;
    }

    function img_load() {
      progress += percent;
      loadedImg++;
      /*if (progress >= 100 || loadedImg === imagesCount) {
        // preloader.delay(400).fadeOut('slow');
        // dBody.css('overflow', '');
      }*/
      bar.circularProgress('animate', progress, 1000);
    }
  }

});

// Добавление класса к header в зависимости от скроллинга страницы. Только для главной страницы
(function () {
  let headerSite = document.querySelector('.js-header-sticky');
  if (headerSite && window.matchMedia("(min-width: 768px)").matches) {
    let headerSiteHeight = headerSite.clientHeight;

    document.addEventListener('scroll', function () {
      let positivePositionAxisY = document.body.getBoundingClientRect().y * (-1);

      if (positivePositionAxisY > headerSiteHeight) {
        headerSite.classList.add('header--sticky');
      } else {
        headerSite.classList.remove('header--sticky');
      }
    });

  }
  document.dispatchEvent(new Event('scroll'));

})();

// Зацикливание фокуса для доступности в модалках
(function () {
  const
    MODAL_ELEMENT_FOCUSED = 'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, [tabindex="0"]',
    MODAL_FOCUS_TRAP_CLASS_NAME = 'js-trap-focus';

  function setFocusTrap(focusWrap) {
    let
      focusedElements = focusWrap.querySelectorAll(MODAL_ELEMENT_FOCUSED),
      firstFocusElement = focusedElements[0],
      lastFocusElement = focusedElements[focusedElements.length - 1],
      defaultFocusElements = focusWrap.querySelectorAll('.' + MODAL_FOCUS_TRAP_CLASS_NAME);

    if (defaultFocusElements.length === 0) {
      let
        createFocusTrap = window.utils.createDivWithClass(MODAL_FOCUS_TRAP_CLASS_NAME),
        cloneCreateFocusTrap = createFocusTrap.cloneNode();

      cloneCreateFocusTrap.setAttribute('tabindex', '0');
      createFocusTrap.setAttribute('tabindex', '0');

      focusWrap.appendChild(createFocusTrap);
      focusWrap.insertAdjacentElement('afterbegin', cloneCreateFocusTrap);

      // Зацикливание фокуса внутри модалки
      let focusTraps = focusWrap.children;

      if (!lastFocusElement) {
        lastFocusElement = focusTraps[focusTraps.length - 1];
      }
      if (!firstFocusElement) {
        firstFocusElement = focusTraps[0];
      }

      firstFocusElement.focus();

      focusTraps[focusTraps.length - 1].addEventListener('focus', function () {
        firstFocusElement.focus();
      });
      focusTraps[0].addEventListener('blur', function () {
        lastFocusElement.focus();
      });
    } else {
      defaultFocusElements.forEach(function (current) {
        current.remove();
      })
    }

  }

  window.focusTrap = {
    setFocusTrap: setFocusTrap
  }

})();

// Инкремент|Дикремент числа в инпуте
(function () {
  const
    NUMBER_PARENT = 'js-numeric',
    NUMBER_INCREMENT = 'js-numeric-plus',
    NUMBER_DECREMENT = 'js-numeric-minus',
    NUMBER_INPUT_TARGET = 'js-numeric-target',
    DEFAULT_NUMBER_MAX = 10,
    DEFAULT_NUMBER_MIN = 0;

  let numericParent = window.utils.getArrayFrom(document.querySelectorAll('.' + NUMBER_PARENT));

  numericParent.forEach(function (current) {
    let
      numIncrementButton = current.querySelector('.' + NUMBER_INCREMENT),
      numDecrementButton = current.querySelector('.' + NUMBER_DECREMENT),
      numTargetInput = current.querySelector('.' + NUMBER_INPUT_TARGET);

    function incrementNumber() {
      let maxValue = +numTargetInput.dataset.maxValue || DEFAULT_NUMBER_MAX;

      if (+numTargetInput.value < maxValue) {
        numTargetInput.value++;
      }
    }

    function decrementNumber() {
      let minValue = +numTargetInput.dataset.minValue || DEFAULT_NUMBER_MIN;

      if (+numTargetInput.value > minValue) {
        numTargetInput.value--;
      }
    }

    numIncrementButton.addEventListener('click', incrementNumber);
    numIncrementButton.addEventListener('keyDown', function (evt) {
      evt.preventDefault();

      if (evt.key === window.utils.keyEnter) {
        incrementNumber();
      }
    });

    numDecrementButton.addEventListener('click', decrementNumber);
    numDecrementButton.addEventListener('keyDown', function (evt) {
      evt.preventDefault();

      if (evt.key === window.utils.keyEnter) {
        decrementNumber();
      }
    });
  });
})();

// Модальное окно
(function () {
  const
    OVERLAY_CLASS_NAME = 'modal-overlay',
    MODAL_BUTTON_CLOSE_CLASS_NAME = 'js-modal-close',
    TRANSITION_TIME = 0.4;

  let createOverModal = window.utils.createDivWithClass(OVERLAY_CLASS_NAME);

  document.body.appendChild(createOverModal);

  let alexModal = function (modal) {
    let
      buttonModalShow = modal,
      modalWindow = document.querySelector(buttonModalShow.dataset.id),
      buttonModalHide = modalWindow.querySelectorAll('.' + MODAL_BUTTON_CLOSE_CLASS_NAME),
      modalOverlay = document.querySelector('.' + OVERLAY_CLASS_NAME),
      body = document.body;

    function openModal() {
      let scrollBarWidth = window.utils.getScrollbarWidth();

      body.classList.add('modal-open');

      document.documentElement.style.setProperty('--scroll-width', scrollBarWidth + 'px');
      modalWindow.style.paddingRight = scrollBarWidth + 'px';

      modalOverlay.style.display = 'block';
      modalWindow.style.display = 'block';
      setTimeout(function () {
        modalOverlay.classList.add('this-show');

        modalWindow.classList.remove('modal-hidden');
      }, 100);

      document.addEventListener('keydown', documentKeyDownHandler);
      modalWindow.addEventListener('click', modalWindowClickHandler);

      // Доступность для модалк
      modalWindow.removeAttribute('aria-hidden');

      window.focusTrap.setFocusTrap(modalWindow);

    }

    function closeModal() {
      modalWindow.classList.add('modal-hidden');

      modalOverlay.classList.remove('this-show');

      // Вешаю отсчёт времени для отработки CSS анимации
      setTimeout(function () {
        modalOverlay.style.display = 'none';

        body.classList.remove('modal-open');
        document.documentElement.style.setProperty('--scroll-width', '0');

        modalWindow.style.display = 'none';
        modalWindow.style.paddingRight = '0';
      }, TRANSITION_TIME * 1000);

      // Доступность для модалк
      modalWindow.setAttribute('aria-hidden', 'true');

      // Убираю все события после закрытия модалки
      if (buttonModalHide) {
        buttonModalHide.forEach(function (current) {
          current.removeEventListener('click', closeModal);
        });
      }
      modalWindow.removeEventListener('click', modalWindowClickHandler);
      document.removeEventListener('keydown', documentKeyDownHandler);

      window.focusTrap.setFocusTrap(modalWindow);
    }

    function documentKeyDownHandler(evt) {
      if (evt.key === window.utils.keyEscape && !evt.target.matches('input, textarea, select')) {
        closeModal();
        // console.log(evt);
      }
    }

    function modalWindowClickHandler(evt) {
      if (evt.target === modalWindow) {
        closeModal();
      }
    }

    buttonModalShow.addEventListener('click', function () {
      openModal();

      // После клика по кнопке открытия модалки
      // добавляю событие клика по кнопке закрытия
      if (buttonModalHide) {
        buttonModalHide.forEach(function (current) {
          current.addEventListener('click', closeModal);
        });
      }
    });

    buttonModalShow.addEventListener('keydown', function (evt) {
      if (evt.key === window.utils.keyEnter && !buttonModalShow.matches('button, a')) {
        openModal();
        if (buttonModalHide) {
          buttonModalHide.forEach(function (current) {
            current.addEventListener('click', closeModal);
          });
        }
      }
    });

    if (modalWindow.classList.contains('open')) {
      openModal();

      // После клика по кнопке открытия модалки
      // добавляю событие клика по кнопке закрытия
      if (buttonModalHide) {
        buttonModalHide.forEach(function (current) {
          current.addEventListener('click', closeModal);
        });
      }
    }
  };


  window.modal = {
    alexModal: alexModal
  }

})();

// Анимация фокуса на input, доработки
(function () {
  let inputFocusWrap = document.querySelectorAll('.input-focus');

  function inputBlurHandler(evt, index) {
    if (evt.target && evt.target.matches('input, textarea') && evt.target.value !== '') {
      inputFocusWrap[index].classList.add('active');
    } else {
      inputFocusWrap[index].classList.remove('active');
    }
  }

  inputFocusWrap.forEach(function (current, index) {
    let inputFocus = current.querySelector('input, textarea');

    inputFocus.addEventListener('blur', function (evt) {
      inputBlurHandler(evt, index);
    });
  });

})();

// Основное меню сайта
(function () {
  const TRANSITION_TIME = 900;

  let
    menuButton = document.querySelector('.js-menu'),
    menuWindow = document.querySelector(menuButton.dataset.id),
    menuCloseButton = menuWindow.querySelector('.js-mapsite-close');

  function openWindow() {
    document.documentElement.style.setProperty('--scroll-width', window.utils.getScrollbarWidth() + 'px');

    document.body.classList.add('body-overflow');

    menuWindow.setAttribute('aria-hidden', 'false');
    menuWindow.style.display = 'block';

    menuButton.classList.add('this-hidden');

    window.focusTrap.setFocusTrap(menuWindow);

    setTimeout(function () {
      menuWindow.classList.remove('this-hidden');
    }, 100);

    if (window.matchMedia("(min-width: 1370px)").matches) {
      document.querySelectorAll('.js-hover-parent').forEach(function (current) {
        window.tabHover.tabHoverWork(current);
      });
    } else {
      document.querySelectorAll('.js-hover-parent').forEach(function (current) {
        current.querySelector('.js-hover-item').classList.add('js-tab-item');
        current.querySelector('.js-hover-target').classList.add('js-tab-target');

        current.querySelector('.js-hover-item').querySelectorAll('a').forEach(function (link) {
          link.addEventListener('click', function (e) {
            e.preventDefault();
          })
        });
        window.tab.tabWork(current);
      });
    }

    menuCloseButton.addEventListener('click', closeWindow);
    menuCloseButton.addEventListener('keydown', buttonKeyDownHandler);
    document.addEventListener('keydown', documentKeyDownHandler);
  }

  function documentKeyDownHandler(evt) {
    if (evt.key === window.utils.keyEscape) {
      closeWindow();
    }
  }

  function buttonKeyDownHandler(evt) {
    if (evt.key === window.utils.keyEnter) {
      closeWindow();
    }
  }

  function closeWindow() {
    document.documentElement.style.setProperty('--scroll-width', '0');

    document.body.classList.remove('body-overflow');
    menuWindow.classList.add('this-hidden');

    menuWindow.setAttribute('aria-hidden', 'true');

    menuButton.setAttribute('hidden', true);

    window.focusTrap.setFocusTrap(menuWindow);

    setTimeout(function () {
      menuWindow.style.display = 'none';
    }, TRANSITION_TIME);

    setTimeout(function () {
      menuButton.classList.remove('this-hidden');
    }, TRANSITION_TIME);

    document.querySelectorAll('.js-hover-parent').forEach(function (current) {
      window.tabHover.tabHoverRemove(current);
    });

    document.removeEventListener('keydown', documentKeyDownHandler);
    menuCloseButton.removeEventListener('click', closeWindow);
    menuCloseButton.removeEventListener('keydown', closeWindow);
  }

  menuButton.addEventListener('click', openWindow);

})();

// Инициализация Слайдеров
(function () {
  [].forEach.call(document.querySelectorAll('.js-slider-parent'), function (container) {
    let sliderElement = container.querySelector('.js-slider');

    let slider = tns({
      container: sliderElement,
      items: +sliderElement.dataset.items || 1,
      center: sliderElement.dataset.center || false,
      loop: !sliderElement.dataset.loop,
      startIndex: (sliderElement.dataset.reverse) ? sliderElement.childElementCount - 1 : 0,
      mouseDrag: true,
      nav: sliderElement.dataset.nav || false,
      rewind: sliderElement.dataset.rewind || false,
      autoWidth: sliderElement.dataset.autoWidth || false,
      autoplay: sliderElement.dataset.autoPlay || false,
      autoplayDirection: sliderElement.dataset.autoplayDirection || "forward",
      autoplayButtonOutput: false,
      speed: 1000,
      navPosition: 'bottom',
      autoHeight: !sliderElement.dataset.autoHeight,
      controls: !sliderElement.dataset.controls,
      controlsText: ['<span class="tiny-arrow tiny-arrow--left"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 302.5 511.975"><path d="M25.3 8.2L8.4 25C3.1 30.3.2 37.3.2 44.8s2.9 14.5 8.2 19.8l191.3 191.3L8.2 447.4C2.9 452.7 0 459.7 0 467.2s2.9 14.6 8.2 19.8L25 503.8c10.9 10.9 28.7 10.9 39.6 0l228.9-228c5.3-5.3 9-12.3 9-19.9v-.1c0-7.5-3.7-14.5-9-19.8L65.2 8.2C59.9 2.9 52.7 0 45.2 0S30.5 2.9 25.3 8.2z"/></svg></span>', '<span class="tiny-arrow tiny-arrow--right"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 302.5 511.975"><path d="M25.3 8.2L8.4 25C3.1 30.3.2 37.3.2 44.8s2.9 14.5 8.2 19.8l191.3 191.3L8.2 447.4C2.9 452.7 0 459.7 0 467.2s2.9 14.6 8.2 19.8L25 503.8c10.9 10.9 28.7 10.9 39.6 0l228.9-228c5.3-5.3 9-12.3 9-19.9v-.1c0-7.5-3.7-14.5-9-19.8L65.2 8.2C59.9 2.9 52.7 0 45.2 0S30.5 2.9 25.3 8.2z"/></svg></span>'],
      responsive: {
        320: {
          edgePadding: 0,
          gutter: 0,
          items: 1
        },
        768: {
          edgePadding: +sliderElement.dataset.edgePadding || 0,
          gutter: +sliderElement.dataset.gutter || 0,
          items: (+sliderElement.dataset.items >= 4) ? 3 : 1,
        },
        1024: {
          edgePadding: +sliderElement.dataset.edgePadding || 0,
          gutter: +sliderElement.dataset.gutter || 0,
          items: +sliderElement.dataset.items || 1,
        },
      }
    });

    let setInfoSlider = function (e) {
      let
        numberDOMElement = container.querySelector('.js-tiny-info-numbers') || false,
        textDOMElement = container.querySelector('.js-tiny-info-text') || false,
        contentBlock = container.querySelectorAll('.js-slider-content'),
        contentBlockActive = container.querySelector('.js-slider-content.active') || false;

      contentBlock = (contentBlock.length !== 0) ? contentBlock : false;

      // Перерасчёт индекса активного элемента слайдера, если отсчёт начинается с конца
      let currentIndexDisplay = (sliderElement.dataset.reverse) ? (e.displayIndex - (e.pages + 1)) * -1 : e.displayIndex;

      if (contentBlock) {
        contentBlockActive.classList.remove('active');
        contentBlock[e.displayIndex - 1].classList.add('active');
      }

      if (numberDOMElement) {
        numberDOMElement.textContent = window.utils.setZeroFirst(currentIndexDisplay) + ' / ' + window.utils.setZeroFirst(e.pages);
      }
      if (textDOMElement) {
        textDOMElement.textContent = e.slideItems[e.displayIndex].dataset.info;
      }
    };

    setInfoSlider(slider.getInfo());

    slider.events.on('transitionStart', setInfoSlider);
  });

})();

// Календарь для регистрации (в разметке два инпута)
(function () {
  if ($('.input-daterange').length) {
    !function (a) {
      a.fn.datepicker.dates["uz-latn"] = {
        days: ["Yakshanba", "Dushanba", "Seshanba", "Chorshanba", "Payshanba", "Juma", "Shanba"],
        daysShort: ["Yak", "Du", "Se", "Chor", "Pay", "Ju", "Sha"],
        daysMin: ["Ya", "Du", "Se", "Cho", "Pa", "Ju", "Sha"],
        months: ["Yanlet", "Fevral", "Mart", "Aprel", "May", "Iyun", "Iyul", "Avgust", "Sentabr", "Oktabr", "Noyabr", "Dekabr"],
        monthsShort: ["Yan", "Fev", "Mar", "Apr", "May", "Iyn", "Iyl", "Avg", "Sen", "Okt", "Noy", "Dek"],
        today: "Bugun",
        clear: "O'chirish",
        format: "dd.mm.yyyy",
        weekStart: 1,
        monthsTitle: "Oylar"
      }
    }(jQuery);
    !function (a) {
      a.fn.datepicker.dates.ru = {
        days: ["Воскресенье", "Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота"],
        daysShort: ["Вск", "Пнд", "Втр", "Срд", "Чтв", "Птн", "Суб"],
        daysMin: ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],
        months: ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"],
        monthsShort: ["Янв", "Фев", "Мар", "Апр", "Май", "Июн", "Июл", "Авг", "Сен", "Окт", "Ноя", "Дек"],
        today: "Сегодня",
        clear: "Очистить",
        format: "dd.mm.yyyy",
        weekStart: 1,
        monthsTitle: "Месяцы"
      }
    }(jQuery);

    $('.input-daterange').datepicker({
      format: "dd MM yyyy",
      todayHighlight: true,
      startDate: "today",
      language: currentLang,
      datesDisabled: dateDisabled,
      autoclose: true
    });
  }
})();

// Переключение табов
(function () {
  let tabWork = function (tabParent) {
    let
      tabArray = window.utils.getArrayFrom(tabParent.querySelector('.js-tab-item').children),
      tabTargetArray = window.utils.getArrayFrom(tabParent.querySelector('.js-tab-target').children),
      tabActive = tabParent.querySelector('.js-tab-item .active');

    let calculateHeight = function () {
      let targetActive = tabParent.querySelector('.js-tab-target .active');

      setTimeout(function () {
        tabParent.querySelector('.js-tab-target').style.minHeight = targetActive.offsetHeight + 'px';
      }, 500);
    };

    calculateHeight();

    let tabIndicator = window.utils.createDivWithClass('tab-indicator');
    tabParent.querySelector('.js-tab-item').appendChild(tabIndicator);

    let calculateYAxisIndicator = function () {
      tabIndicator.style.transform = 'translateX(' + tabActive.offsetLeft + 'px)';
      tabIndicator.style.width = tabActive.clientWidth + "px";
    };
    calculateYAxisIndicator();

    function toggleTabEvent(current, index) {
      let activeArray = Array.from(tabParent.querySelectorAll('.active'));

      activeArray.forEach(function (activeCurrent) {
        activeCurrent.classList.remove('active');
      });

      current.classList.add('active');
      tabTargetArray[index].classList.add('active');

      tabActive = current;
      calculateYAxisIndicator();
      calculateHeight();

      window.tooltip.tooltipPosition();
    }

    tabArray.forEach(function (current, index) {
      current.addEventListener('click', function () {
        toggleTabEvent(current, index);
      });

      current.addEventListener('keydown', function (evt) {
        if (evt.key === window.utils.keyEnter || evt.key === window.utils.keySpace) {
          toggleTabEvent(current, index);
        }
      });
    });
  };

  let tabRemove = function (tabParent) {
    tabParent.querySelector('.tab-indicator').remove();
  };

  window.tab = {
    tabWork: tabWork,
    tabRemove: tabRemove
  }

})();

// Переключение табов
(function () {
  let tabWork = function (tabParent) {
    let
      tabArray = window.utils.getArrayFrom(tabParent.querySelector('.js-hover-item').children),
      tabTargetArray = window.utils.getArrayFrom(tabParent.querySelector('.js-hover-target').children),
      tabActive = tabParent.querySelector('.js-hover-item .active');

    let tabIndicator = window.utils.createDivWithClass('tab-indicator');
    tabParent.querySelector('.js-hover-item').appendChild(tabIndicator);

    let calculateYAxisIndicator = function () {
      tabIndicator.style.transform = 'translateY(' + ((tabActive.offsetTop + (tabActive.clientHeight / 2)) - (tabIndicator.clientHeight / 2)) + 'px)';
    };
    calculateYAxisIndicator();

    function toggleTabEvent(current, index) {
      let activeArray = Array.from(tabParent.querySelectorAll('.active'));

      activeArray.forEach(function (activeCurrent) {
        activeCurrent.classList.remove('active');
      });

      current.classList.add('active');
      tabTargetArray[index].classList.add('active');

      tabActive = current;
      calculateYAxisIndicator();
    }

    tabArray.forEach(function (current, index) {
      current.addEventListener('mouseover', function () {
        toggleTabEvent(current, index);
      });

      current.addEventListener('keydown', function (evt) {
        if (evt.key === window.utils.keyEnter || evt.key === window.utils.keySpace) {
          toggleTabEvent(current, index);
        }
      });
    });
  };

  let tabRemove = function (tabParent) {
    tabParent.querySelector('.tab-indicator').remove();
  };

  window.tabHover = {
    tabHoverWork: tabWork,
    tabHoverRemove: tabRemove
  }

})();


// Плавный скроллинг к элементу
(function () {
  let scrollButton = document.querySelectorAll('.js-scroll-to');

  scrollButton.forEach(function (current) {
    current.addEventListener('click', function () {
      let
        idElemntToScroll = current.dataset.id || document.body, // {String|HTMLElement} data-id — ID элемента для прокрутки || по умолчанию элемента body
        dataDuration = +current.dataset.duration || 1500; // {Number} data-duration — длительность прокрутки || по умолчанию 1500

      window.utils.scrollPageTo(idElemntToScroll, dataDuration).then();
    })
  });

})();

// Суммирование чисел с инпутов - узкоспециализирован*****
(function () {
  let
    amountCollectButton = document.querySelector('.js-amount-collect'),
    amountCollectParent = document.querySelector('.js-amount-parent'),
    amountGuestInput = document.querySelector('.js-amount-guest');

  if (amountCollectButton && amountCollectParent && amountGuestInput) {
    function setAmountInputValue() {
      amountGuestInput.value = window.utils.collectNumberFromInputs(amountCollectParent.querySelectorAll('input'));
    }

    amountCollectButton.addEventListener('click', setAmountInputValue);
    amountCollectButton.addEventListener('keyDown', setAmountInputValue);
  }
})();

// Тултип
(function () {
  let tooltipElement = document.querySelector('.js-tooltip');
  if (tooltipElement) {
    let
      tooltipBox = document.querySelector(tooltipElement.dataset.id),
      tooltipCloseButton = tooltipBox.querySelector('.js-tooltip-close');

    function documentClickHandler(evt) {
      let target = evt.target;

      if (target.closest('#' + tooltipBox.id) !== tooltipBox) {
        closeTooltip();
      }
    }

    function documentKeyDownHandler(evt) {
      if (evt.key === window.utils.keyEscape) {
        closeTooltip();
      }
    }

    function tooltipCloseButtonKeyDownHandler(evt) {
      if (evt.key === window.utils.keyEnter) {
        closeTooltip();
      }
    }

    function openTooltip(evt) {
      evt.preventDefault();

      tooltipBox.style.display = 'block';
      tooltipElement.setAttribute('aria-expanded', 'true');

      document.addEventListener('click', documentClickHandler, true);
      tooltipCloseButton.addEventListener('click', closeTooltip);
      tooltipCloseButton.addEventListener('keyDown', tooltipCloseButtonKeyDownHandler);

      document.addEventListener('keydown', documentKeyDownHandler);
    }

    function closeTooltip() {
      tooltipBox.style.display = 'none';
      tooltipElement.setAttribute('aria-expanded', 'false');

      document.removeEventListener('click', documentClickHandler, true);
      document.removeEventListener('keydown', documentKeyDownHandler);
      tooltipCloseButton.removeEventListener('click', closeTooltip);
      tooltipCloseButton.removeEventListener('keyDown', tooltipCloseButtonKeyDownHandler);
    }

    tooltipElement.addEventListener('click', openTooltip);

    tooltipElement.addEventListener('keydown', function (evt) {
      if (evt.key === window.utils.keyEnter) {
        openTooltip(evt);
      }
    });
  }
})();

//  Раскрывает выпадающий блок
(function () {
  /**
   * @param  {Object} path             Путь до елемента с которого всё начинается (стрелка)
   * @param  {String} parent_name      Класс родителя откуда от места начала (".exampleClass")
   * @param  {String} children_in      Класс скрытого блока (".exampleClass")
   * @param  {String | Number} duration_h  Время исполнения анимации (slow|normal|fast)
   * @param  {Boolean} hidden_path     Скрывать path элемент (true) или нет (false)
   * @param  {Number} a_time           Задаёт задержку раскрытия в милисекундах
   * @return {undefined}
   */
  function wiyzjalka(path, parent_name, children_in, duration_h, hidden_path, a_time) {
    var a_time = (!a_time) ? 0 : a_time;
    path.each(function () {
      var thisHand = $(this);

      if ($(this).parents(parent_name).find(children_in).find(".js-active").length > 0 || $(this).hasClass('js-open')) {
        $(this).parents(parent_name).addClass("js-open");
        $(this).parents(parent_name).find(children_in).stop().delay(a_time).slideDown(duration_h);
        if ("true" === $(this).parents(parent_name).find(children_in).attr('aria-hidden')) {
          $(this).parents(parent_name).find(children_in).attr('aria-hidden', 'false');
        } else {
          $(this).parents(parent_name).find(children_in).attr('aria-hidden', 'true');
        }
      }

      function openPandora() {
        if ($(children_in).find('.hide-box').length) {
          let dMSec = (duration_h === "slow") ? 600
            : (duration_h === "normal") ? 400
              : (duration_h === "fast") ? 200 : 0
          setTimeout(function () {
            toolboxSetClass($('.hide-box'));
          }, dMSec + a_time);
        }
        if (hidden_path) {
          thisHand.hide();
        }

        setTimeout(function () {
          window.tooltip.tooltipPosition();
        }, (a_time === 0) ? 100 : a_time);

        path.parents(parent_name).find(children_in).stop(true, false).slideUp(duration_h);
        path.parents(parent_name).removeClass("js-open");
        path.parents(parent_name).removeClass("js-active");
        if (thisHand.parents(parent_name).find(children_in).is(children_in)) {
          if (thisHand.parents(parent_name).find(children_in).is(":visible")) {
            thisHand.parents(parent_name).removeClass("js-open");
            thisHand.parents(parent_name).find(children_in).stop().slideUp(duration_h, function () {
              // console.log(thisHand.css());
              thisHand.css("height", "auto");
            });
            if (thisHand.parents(parent_name).find(children_in).attr('aria-hidden') === "true") {
              thisHand.parents(parent_name).find(children_in).attr('aria-hidden', 'false');
            } else {
              thisHand.parents(parent_name).find(children_in).attr('aria-hidden', 'true');
            }
          } else {
            if (thisHand.parents(parent_name).find(children_in).attr('aria-hidden') === "true") {
              thisHand.parents(parent_name).find(children_in).attr('aria-hidden', 'false');
            } else {
              thisHand.parents(parent_name).find(children_in).attr('aria-hidden', 'true');
            }
            thisHand.parents(parent_name).addClass("js-open");
            thisHand.parents(parent_name).find(children_in).stop().delay(a_time).slideDown(duration_h);
          }
        }
      }

      thisHand.bind("click", openPandora);
      thisHand.on("keydown", function (e) {
        if (e.keyCode === 13) {
          openPandora()
        }
      });
    });
  }

  window.collapse = {
    wiyzjalka: wiyzjalka
  }
})();

(function () {
  // Let the document know when the mouse is being used
  document.body.addEventListener('mousedown', function () {
    document.body.classList.add('using-mouse');
  });

  // Re-enable focus styling when Tab is pressed
  document.body.addEventListener('keydown', function (event) {
    if (event.keyCode === 9) {
      document.body.classList.remove('using-mouse');
    }
  });
})();

//  Дефолтный поток
(function () {

  (function () {
    let aosElements = document.querySelectorAll('.js-aos > *');
    aosElements.forEach(function (current) {
      current.setAttribute('data-aos', 'fade-up');
    })
  })();

  AOS.init({disable: 'mobile'});


  (function () {
    let back = document.querySelector('.js-menu-back');
    let target = document.querySelector('.js-target-back');
    let y = target.getBoundingClientRect().top;
    let x = target.getBoundingClientRect().left;
    back.style.top = (y + 1) + 'px';
    back.style.left = (x + 1) + 'px';
  })();

  (function () {
    let hoverMenu = document.querySelectorAll('.js-hover');
    hoverMenu.forEach(function (current, num) {
      current.addEventListener('mouseover', function (e) {
        hoverMenu[0].parentElement.querySelector('.active').classList.remove('active');
        e.target.classList.add('active');
      })
    })
  })();

  document.querySelectorAll('.js-tab-parent').forEach(function (current) {
    window.tab.tabWork(current);
  });

  if (document.querySelector('.calc-tab')) {
    window.tab.tabRemove(document.querySelector('.calc-tab'));
  }

  if (window.matchMedia("(min-width: 768px)").matches) {
    document.querySelectorAll('.js-transition-parent').forEach(function (current) {
      window.utils.setTransitionForChildren(current);
    });
  } else {
    window.utils.setTransitionForChildren(document.querySelector('.mobile-menu-wrap .js-transition-parent'));
  }

  document.querySelectorAll('.js-modal').forEach(function (current) {
    window.modal.alexModal(current);
  });

  if ($('.js-pandora-parent').length) {
    window.collapse.wiyzjalka($('.js-pandora-hand'), '.js-pandora-parent', '.js-pandora-box', 'normal', false, 0);
  }

  if ($('.js-tariff-tab-parent').length) {
    window.collapse.wiyzjalka($('.js-tariff-tab-hand'), '.js-tariff-tab-parent', '.js-tariff-tab-box', 'normal', true, 0);
  }

  /* Анимация поездки грузовика */
  (function () {
    let
      truckParent = document.querySelector('.js-truck-parent');

    if (truckParent) {
      let truck = truckParent.querySelector('.js-truck-animation');
      let truckItems = truckParent.querySelectorAll('.plan-item');

      truckItems.forEach(function (current, index) {
        let
          delay = ((index + 1) * 4.2 / truckItems.length),
          icon = current.querySelector('svg');

        current.style.transitionDelay = delay + 's';
        if (icon) {
          icon.style.transitionDelay = delay + 's';
        }
      });

      document.addEventListener('scroll', function () {
        let
          positivePositionAxisY = document.body.getBoundingClientRect().y * (-1) + window.innerHeight,
          truckPositionCheck = (truck.getBoundingClientRect().y + window.innerHeight + 500) - positivePositionAxisY;

        if (truckPositionCheck < 0) {
          truckParent.classList.add('truck-active');
        }
      });
    }
  })();

  (function () {
    let scrollActive = document.querySelectorAll('.js-scroll-active');

    if (scrollActive.length > 0) {
      document.addEventListener('scroll', function () {
        let positivePositionAxisY = document.body.getBoundingClientRect().y * (-1) + window.innerHeight;

        scrollActive.forEach(function (current) {
          let activeScrollPosition = current.getBoundingClientRect().y + window.innerHeight + 500 - positivePositionAxisY;
          if (activeScrollPosition < 0) {
            current.classList.add('scroll-active');
          }
        })

      });
    }
  })();

  (function () {
    $('.js-region-btn').each(function () {
      if ($(this).hasClass('pin-active')) {
        $(this).append('<span class="region-map-pin"><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 19.553 29.629"><defs><path id="a" d="M0 0h19.553v29.629H0z"/></defs><clipPath id="b"><use xlink:href="#a" overflow="visible"/></clipPath><path clip-path="url(#b)" fill="#FBB040" d="M19.553 9.776C19.553 4.377 15.176 0 9.777 0S.001 4.377.001 9.776c0 0-.243 9.833 9.776 19.853 10.019-10.02 9.776-19.853 9.776-19.853"/><path clip-path="url(#b)" fill="#F7941E" d="M9.919.002A6.724 6.724 0 009.776 0C4.377 0 .001 4.377.001 9.776c0 0-.244 9.834 9.775 19.853.049-.048.096-.096.143-.145V.002z"/><path clip-path="url(#b)" fill="#FFF" d="M13.771 9.755a3.995 3.995 0 11-7.99 0 3.995 3.995 0 017.99 0"/></svg></span>');
        let h = '-15px';
        $(this).children('.region-map-pin').css({
          'left': ($(this).find('.region-map-pin').outerWidth() / 2 - $(this).width() / 2),
          'top': h
        });

        $(this).mouseover(function (e) {
          let current = e.currentTarget.dataset.region;
          $('.js-filial[data-region=' + current + ']').addClass('active');
        });

        $(this).mouseout(function (e) {
          let current = e.currentTarget.dataset.region;
          $('.js-filial[data-region=' + current + ']').removeClass('active');
        });

        $(this).click(function (e) {
          let current = e.currentTarget.dataset.region;
          $('.js-map-parent').addClass('active');
          $('.js-filial[data-region=' + current + ']').addClass('map-active');
        });
      }

    });
    $('.js-filial').each(function () {
      $(this).mouseover(function (e) {
        let current = e.currentTarget.dataset.region;
        $('.js-region-btn[data-region=' + current + ']').addClass('active');
      });

      $(this).mouseout(function (e) {
        let current = e.currentTarget.dataset.region;
        $('.js-region-btn[data-region=' + current + ']').removeClass('active');
      })
    })
  })();

  (function () {

    let tooltipPosition = function () {
      let tooltip = document.querySelectorAll('.js-tooltip-position');

      if (tooltip.length > 0) {
        tooltip.forEach(function (current) {
          let tooltipBox = current.querySelector('.tooltip__box');
          let positionTooltipBox = tooltipBox.getBoundingClientRect().x;

          if (positionTooltipBox < 0) {
            current.classList.add('tooltip--invert');
          }
        })
      }
    };

    window.tooltip = {
      tooltipPosition: tooltipPosition
    }
  })();

  window.tooltip.tooltipPosition();

  (function () {
    let mobileButtonMenu = document.querySelector('.js-mobile-menu');
    mobileButtonMenu.addEventListener('click', function (e) {
      e.preventDefault();
      let mobileModal = document.querySelector(mobileButtonMenu.dataset.id);

      document.body.classList.toggle('menu-modal-open');
      mobileModal.classList.toggle('active');
      mobileButtonMenu.classList.toggle('active');
    })
  })();

})();


// Подгрузка блока из <template> в зависимости от того какая опция выбранна в селекте
(function () {
  function packSelect() {
    let selectElements = document.querySelectorAll('.js-pack'); // Общий врапер всего блока

    if (selectElements.length > 0) {
      selectElements.forEach(function (current) {
        let packWrap = current.querySelector('.js-pack-wrap'); // Обёртка для подгрузки элементов

        let packSelectOnChange = function (evt) {
          let
            target = evt.target,
            optionSelected = target.options[target.options.selectedIndex],
            template = document.querySelector(optionSelected.dataset.pack).content.querySelector('.js-pack-item'),
            cloneTemplate = template.cloneNode(true);

          if (packWrap.querySelector('.js-pack-item')) {
            packWrap.querySelector('.js-pack-item').remove();
          }

          packWrap.appendChild(cloneTemplate);
        };

        current.querySelector('.js-pack-select').addEventListener('change', function (evt) {
          packSelectOnChange(evt);
        });

        current.querySelector('.js-pack-select').dispatchEvent(new Event('change'));
      })
    }
  }

  window.pack = {
    select: packSelect
  }

})();

// Вывод сообщения о событии
(function () {
  const ERROR_COLOR = 'orangeRed';
  const SUCCESS_COLOR = '#1cb34d';
  const ERROR_SHOW_TIME = 6000;
  const TRANSITION_DURATION = 0.5;

  // Немедленное закрытие окна
  let closeMessage = function (block, isSuccess) {
    let
      colorSvg = (isSuccess) ? SUCCESS_COLOR : ERROR_COLOR,
      closeButton = document.createElement('button');

    closeButton.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="' + colorSvg + '"><path d="M512 60.3L451.7 0 256 195.7 60.3 0 0 60.3 195.7 256 0 451.7 60.3 512 256 316.3 451.7 512l60.3-60.3L316.3 256 512 60.3z"/></svg>';
    closeButton.classList.add('close-message-popup');

    let closeButtonHandler = function (evt) {
      evt.preventDefault();

      hideMessage(block);

      closeButton.removeEventListener('click', closeButtonHandler);
    };

    closeButton.addEventListener('click', closeButtonHandler);

    block.appendChild(closeButton);

  };

  // Анимация закрытия окна в одельной функции
  let hideMessage = function (block) {
    block.style.top = '-50%';

    setTimeout(function () {
      block.remove();
    }, TRANSITION_DURATION * 1000);
  };

  // Закрытие окна с задержкой
  let hideTimeoutMessage = function (block, isSuccess) {
    let colorTimerBlock = (isSuccess) ? SUCCESS_COLOR : ERROR_COLOR;
    let timerBlock = document.createElement('div');
    let pointOfStart = 100; // Длина визуальной линии с которой начинается остчёт

    timerBlock.setAttribute('style', 'position:absolute; left:0; top:0; height:4px; width:100%; background-color: ' + colorTimerBlock + ';transition: width 0.1s linear');

    let interval = setInterval(function () {
      pointOfStart--;

      timerBlock.style.width = pointOfStart + '%';
    }, (ERROR_SHOW_TIME - 200) / pointOfStart);

    setTimeout(function () {
      clearInterval(interval);

      hideMessage(block);

    }, ERROR_SHOW_TIME);

    block.appendChild(timerBlock);
  };

  // Основная функция показа окна с сообщением
  let showMessage = function (message, isSuccess) {
    let colorMessage = (isSuccess) ? SUCCESS_COLOR : ERROR_COLOR;
    let wrapBlockError = document.createElement('section');
    let titleErrorMessage = document.createElement('h2');
    let textErrorMessage = document.createElement('p');

    wrapBlockError.classList.add('show-popup-message');

    wrapBlockError.setAttribute('style', 'top: -50%; color: ' + colorMessage + '; transition: all ' + TRANSITION_DURATION + 's cubic-bezier(.25,-0.5,0,1.25);');

    // titleErrorMessage.setAttribute('style', 'text-shadow: none;');
    // textErrorMessage.setAttribute('style', 'margin-bottom: 0;');

    titleErrorMessage.textContent = message.title;
    textErrorMessage.textContent = message.text;

    if (message.title) {
      wrapBlockError.appendChild(titleErrorMessage);
    }

    if (message.text) {
      wrapBlockError.appendChild(textErrorMessage);
    }

    document.body.appendChild(wrapBlockError);

    setTimeout(function () {
      wrapBlockError.style.top = '40px';
    }, 100);

    hideTimeoutMessage(wrapBlockError, isSuccess);
    closeMessage(wrapBlockError, isSuccess);
  };

  // Вынос функции в глобальную область видимости
  window.message = {
    showMessage: showMessage
  };

})();

// Input Mask
(() => {
  window.addEventListener('DOMContentLoaded', function () {

    let inputMask = document.querySelectorAll(`.js-latin-input`);

    if (inputMask) {
      inputMask.forEach(function (current) {
        current.addEventListener('input', function (evt) {
          this.value = this.value.replace(/[^A-Za-z0-9\-\s]/ig, '');
        });
      });
    }
  });
})();


// Подгрузка блока из <template> в зависимости от того какой чекбокс выбран
(function () {
  let checkedElement = document.querySelector('.js-send-type'); // Общий врапер всего блока

  if (checkedElement) {
    let
      sendPackWrap = document.querySelector('.js-send-type-wrap'), // Обёртка для подгрузки элементов
      resetButton = checkedElement.querySelector('.js-type-reset'),
      radioButton = checkedElement.querySelectorAll('input[type="radio"]');

    function resetButtonClick() {

      radioButton.forEach(function (cur) {
        cur.removeAttribute('disabled');
        cur.checked = false;
      });

      resetButton.style.display = 'none';
      sendPackWrap.querySelector('.js-send-type-item').remove();

      resetButton.removeEventListener('click', resetButtonClick);
    }

    radioButton.forEach(function (current) {

      let packRadioOnChange = function (evt) {
        let
          target = evt.target;

        if (target.checked) {
          if (resetButton) {
            resetButton.style.display = 'block';
            resetButton.addEventListener('click', resetButtonClick);
          }

          radioButton.forEach(function (cur) {
            cur.setAttribute('disabled', true);
          });

          target.removeAttribute('disabled');

          let
            template = document.querySelector(target.dataset.sendType).content.querySelector('.js-send-type-item'),
            cloneTemplate = template.cloneNode(true);

          if (sendPackWrap.querySelector('.js-send-type-item')) {
            sendPackWrap.querySelector('.js-send-type-item').remove();
          }

          sendPackWrap.appendChild(cloneTemplate);

          window.pack.select();
        }
      };

      current.addEventListener('change', function (evt) {
        packRadioOnChange(evt);
      });

      current.dispatchEvent(new Event('change'));
    })
  }


})();
